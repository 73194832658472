<template>
  <el-form
    :model="formData"
    :rules="rules"
    ref="formRef"
    label-width="140px"
    class="detail-form"
  >
    <el-form-item label="登录名" prop="account">
      <el-input
        size="small"
        v-model="formData.account"
        placeholder="请输入登录名"
      ></el-input>
    </el-form-item>
    <el-form-item label="用户名" prop="name">
      <el-input
        size="small"
        v-model="formData.name"
        placeholder="请输入用户名"
      ></el-input>
    </el-form-item>
    <el-form-item label="密码" prop="password">
      <el-input
        type="password"
        show-password
        size="small"
        v-model="formData.password"
        :disabled="operationType === 'edit'"
        :placeholder="
          operationType == 'create' ? '请输入密码' : '不输入则为不修改密码'
        "
      ></el-input>
    </el-form-item>
    <el-form-item label="邮箱" prop="email">
      <el-input
        size="small"
        v-model="formData.email"
        placeholder="请输入邮箱地址"
      ></el-input>
    </el-form-item>
    <el-form-item label="角色配置" prop="roleId">
      <el-select
        filterable
        size="small"
        v-model="formData.roleId"
        placeholder="请选择角色"
        @change="changeRoleid"
      >
        <el-option
          v-for="item in roleList"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      label="上级商务"
      prop="parentId"
      :rules="[{ required: isShowsuperior, message: '请选择上级商务' }]"
    >
      <el-select
        v-if="isShowsuperior"
        filterable
        v-model="formData.parentId"
        placeholder="请选择上级商务"
      >
        <el-option
          v-for="item in superiorList"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        ></el-option>
      </el-select>
      <span v-else>无</span>
    </el-form-item>
    <el-form-item label="备注" prop="remark">
      <el-input
        size="small"
        type="textarea"
        v-model="formData.remark"
        placeholder="请输入备注"
      ></el-input>
    </el-form-item>

    <el-form-item>
      <el-button size="small" type="primary" @click="handleSubmit()"
        >保存</el-button
      >
      <el-button size="small" @click="handleCancel()">取消</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { businessManage } from "./indexServe";
export default {
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
    roleList: {
      type: Array,
      default() {
        return [];
      },
    },
    operationType: { default: "" },
  },
  data() {
    const checkEmail = (rule, value, callback) => {
      // 校验邮箱
      // eslint-disable-next-line no-useless-escape
      const patt = /^([a-zA-Z0-9_\.\-]+@([a-zA-Z0-9\-]+\.)+[a-z]+)$/;
      if (value && !patt.test(value)) {
        return callback(new Error("请输入正确的邮箱地址"));
      } else {
        callback();
      }
    };
    return {
      defaultProps: {
        label: "text",
        children: "items",
      },
      isShowsuperior: false,
      superior: false,
      superiorList: [],
      formData: { ...this.detailData },
      rules: {
        account: [{ required: true, message: "请输入登录名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        roleId: [
          { required: true, message: "请选择角色配置", trigger: "blur" },
        ],
        email: [
          {
            trigger: ["blur", "change"],
            validator: checkEmail,
          },
        ],
      },
    };
  },
  created() {
    businessManage({ businessLevel: 1, size: 100 }).then((res) => {
      if (res && res.code == 0) {
        this.superiorList = res.data.list || [];
      }
    });
  },
  methods: {
    handleSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          if (!this.isShowsuperior) {
            this.formData.parentId = "";
          }
          this.$emit("submit", this.formData);
        } else {
          this.$message.error("请检查输入的数据");
        }
      });
    },
    handleCancel() {
      this.$emit("cancel");
    },
    changeRoleid(val) {
      console.log(this.formData.parentId);
      let array = this.roleList;
      let newArr = array.filter((item) => item.businessLevel == 2);
      let tt = newArr.filter((item) => item.id == val);
      if (tt.length > 0) {
        this.isShowsuperior = true;
        this.superior = true;
      } else {
        this.isShowsuperior = false;
        this.superior = false;
      }
    },
  },
  watch: {
    detailData(data) {
      this.formData = { ...data };
      if (this.operationType === "create") {
        this.$refs.formRef.resetFields();
      }
    },
    "detailData.parentId": {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.isShowsuperior = true;
        } else {
          this.isShowsuperior = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
